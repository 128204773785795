
import axiosInstance from "./plugins/HTTP";
import adminService from '@/services/admin.service';

export default {
    data() {
        return { /* data */ }
    },
    created: function () {
    },
    methods: {
        com_UpdateTokenAndTime(token) {
            var current = new Date();
            localStorage.removeItem("jwt_token");
            localStorage.removeItem("tkutim");
            localStorage.setItem("jwt_token", token);
            localStorage.setItem("tkutim", current.getTime());
            axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            //console.log("token updated");
        },

        com_setLoginData(param) {
            let rootObject = this;
            if (param.jwt_token) {
                axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + param.jwt_token;
                axiosInstance.get('profile').then(function (response) {
                    localStorage.setItem("jwt_token", param.jwt_token);
                    rootObject.$store.dispatch('changLoginStatus', true);
                    rootObject.$store.dispatch('setUser', response.data.user);
                    rootObject.com_CheckVisitActivityAndTokenStatus();
                    // console.log(response);
                    if (param.redirect_url) {
                        rootObject.$router.push(param.redirect_url);
                    }
                })
                    .catch(function (error) {
                        let alertmessage = "";
                        for (var prop in error.response) {
                            alertmessage += ' ' + error.response[prop]
                        }
                        localStorage.removeItem("jwt_token");
                        rootObject.$store.dispatch('setUser', null);
                        rootObject.$store.dispatch('changLoginStatus', false);
                        rootObject.com_CheckVisitActivityAndTokenStatus(false);
                        console.log(param.redirect_url);
                        console.log(alertmessage);
                        if (param.redirect_url) {
                            rootObject.$router.push(param.redirect_url);
                        } else {
                            rootObject.$router.push('logout');
                        }
                    });
            } else {
                rootObject.$store.dispatch('setServerWSData', {});
                rootObject.$store.dispatch('setWsReloaded', true);
                rootObject.$store.dispatch('setWsConnectOpenStatus', false);
                localStorage.removeItem("jwt_token");
                rootObject.$store.dispatch('setUser', null);
                rootObject.$store.dispatch('changLoginStatus', false);
                rootObject.com_CheckVisitActivityAndTokenStatus(false);
                rootObject.$store.dispatch('setWsServerLoadConnection', {});
                if (param.redirect_url) {
                    rootObject.$router.push(param.redirect_url);
                }
            }
        },
        com_setPanelData(param) {
            //var previousLoginStatus = this.$store.getters.getLoginStatus;
            this.$store.dispatch('setPanelData', param.panel_settings);
            let loginparam = {
                'jwt_token': localStorage.getItem("jwt_token"),
            };
            this.com_setLoginData(loginparam);
        },
        com_CheckVisitActivityAndTokenStatus(startInterval = true) {
            var rootObject = this;
            var intervalObj = null;

            // system will auto logout after this idle time
            var maxIdleTime = process.env.VUE_APP_MAXIDLETIME;

            //Check system activity after this interval
            var intervalTime = process.env.VUE_APP_CHECKACTIVITYINTERVALTIME;

            // after this tim system will go for refressh token
            var tokenRefreshTime = process.env.VUE_APP_TOKENREFRESHTIME;

            if (startInterval) {
                intervalObj = setInterval(function () {
                    var lastActivityTime = localStorage.getItem('lastActivityTime');
                    var lasttokenUpdateTime = localStorage.getItem('tkutim');
                    var current = new Date();
                    var totalIdleTime = parseInt(lastActivityTime) + parseInt(maxIdleTime);
                    //console.log("Hello");
                    //console.log(totalIdleTime +" _ "+current.getTime());
                    if (totalIdleTime < current.getTime()) {
                        //This is Logout
                        intervalObj = localStorage.getItem("visitActvityProcess");
                        clearInterval(intervalObj);
                        let loginparam = {
                            'jwt_token': '',
                            'redirect_url': 'login',
                        };
                        rootObject.com_setLoginData(loginparam);
                    } else {
                        var nextTokenRefreshTime = parseInt(lasttokenUpdateTime) + parseInt(tokenRefreshTime);
                        if (current.getTime() >= nextTokenRefreshTime) {
                            rootObject.com_RefreshToken();
                        }
                    }
                }, intervalTime);
                localStorage.setItem('visitActvityProcess', intervalObj);
            } else {
                //Clear Interval
                intervalObj = localStorage.getItem("visitActvityProcess");
                if (intervalObj) {
                    clearInterval(intervalObj);
                    localStorage.removeItem("visitActvityProcess");
                }
            }
        },
        com_SetLastActivityTime() {
            var current = new Date();
            localStorage.setItem('lastActivityTime', current.getTime());
        },
        com_RefreshToken() {
            var rootObject = this;
            adminService.getRefreshToken().then(function (response) {
                rootObject.com_UpdateTokenAndTime(response.token);
            })
                .catch(function (error) {
                    let alertmessage = "";
                    for (var prop in error.response) {
                        alertmessage += ' ' + error.response[prop]
                    }
                    console.log(alertmessage);
                    rootObject.com_errorHandel(error);
                });
        },
        com_errorHandel(error) {
            if (error.response.status == 401) {
                let loginparam = {
                    'jwt_token': '',
                    'redirect_url': 'login',
                };
                this.com_setLoginData(loginparam);
            }
        },
        com_displayDateFormat(date) {
            var newDate = new Date(date);
            var resultArr = [];
            var convertedDate = newDate.getFullYear()
                + '-'
                + ('0' + (newDate.getMonth() + 1)).slice(-2)
                + '-'
                + ('0' + newDate.getDate()).slice(-2);

            var convertedHour = newDate.getHours();

            resultArr = { date: convertedDate, hour: convertedHour };
            return resultArr;
        },
        com_removeHostedPinPrefix(actualPin, prefix, panel_type) {
            if (panel_type == 'hosted') {
                var newPin = actualPin.replace(prefix, "");
                var hostedPin = newPin;
                if (newPin.charAt(0) === '-') {
                    hostedPin = newPin.substring(1);
                }
                return hostedPin;
            } else {
                return actualPin;
            }
        },
        com_currentDateTime(format = '', time = '') {
            //format = dmy, time = no
            const monthShortNames = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];

            var current = new Date();

            if (format == 'dmy') {
                var date = ('0' + current.getDate()).slice(-2)
                    + ' '
                    + monthShortNames[(current.getMonth())]
                    + ', '
                    + current.getFullYear()
            } else {
                date = current.getFullYear()
                    + '-'
                    + ('0' + (current.getMonth() + 1)).slice(-2)
                    + '-'
                    + ('0' + current.getDate()).slice(-2);
            }

            if (time == '' || time == 'yes') {
                time = ('0' + current.getHours()).slice(-2)
                    + ":"
                    + ('0' + current.getMinutes()).slice(-2)
                    + ":"
                    + ('0' + current.getSeconds()).slice(-2);
            } else {
                time = '';
            }

            var dateTime = date + ' ' + time;
            return dateTime;
        },
        com_modifyDateTime(no_of_days = 0, date = '') {
            if (date != "") {
                var newDate = new Date(date);
            } else {
                newDate = new Date();
            }

            if (parseInt(no_of_days) != 0) {
                newDate.setDate(newDate.getDate() + parseInt(no_of_days));
            }

            var modifiedDate = newDate.getFullYear()
                + '-'
                + ('0' + (newDate.getMonth() + 1)).slice(-2)
                + '-'
                + ('0' + newDate.getDate()).slice(-2)
                + ' ' + ('0' + newDate.getHours()).slice(-2) + ':'
                + ('0' + newDate.getMinutes()).slice(-2) + ':'
                + ('0' + newDate.getSeconds()).slice(-2);
            return modifiedDate;
        },
        com_pageNotFound() {
            this.$router.push('pagenotfound');
        },
    },
}