const LoaderPlugin = {
    install(app) {
      app.config.globalProperties.$loader = {
        show: function() {
          const loader = document.querySelector('.loader');
          if (loader) {
            loader.style.display = 'block';
          }
        },
        hide: function() {
          const loader = document.querySelector('.loader');
          if (loader) {
            loader.style.display = 'none';
          }
        }
      };
    }
  };
  
  export default LoaderPlugin;
  