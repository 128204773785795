import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    name: "guest",
    path: "/login",
    component: () => import("../components/layouts/Guest.vue"),
    meta: {
      middleware: "guest",
    },
    children: [
      {
        path: "",
        name: "login",
        component: () =>
          import(/*webpackChunck: "home" */ "../pages/Auth/LoginView.vue"),
        meta: {
          title: `Log In`,
        },
      },
      {
        path: "/reset-password",
        name: "reset-password",
        component: () =>
          import(
            /*webpackChunck: "home" */ "../pages/Auth/ResetPasswordView.vue"
          ),
        meta: {
          title: `Log In`,
        },
      },
      {
        path: "/pagenotfound",
        name: "pagesnotfound",
        component: () => import("../components/404NotFound.vue"),
      },
    ],
  },
  {
    name: "base",
    path: "/",
    component: () => import("../components/layouts/Default.vue"),
    meta: {
      middleware: "auth",
      requiresAuth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "home" */ "../pages/HomeView.vue"),
        meta: {
          title: `Dashboard`,
        },
      },
      {
        path: "subscribers-list",
        name: "subscriberLists",
        component: () =>
          import(
            /* webpackChunkName: "subscriber" */ "../pages/Subscribers/Subscriber/SubscriberListView.vue"
          ),
        meta: {
          title: `subsciber list`,
        },
      },
      {
        path: "reseller-connected-users",
        name: "connectedusers",
        component: () =>
          import(
            /* webpackChunkName: "subscriber" */ "../pages/Subscribers/ConnectedUser/ConnectedUserView.vue"
          ),
        meta: {
          title: `connected user`,
        },
      },
      {
        path: "add-single-subscriber",
        name: "addsinglesubscriber",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/Subscribers/Subscriber/AddSingleSubscriber.vue"
          ),
        meta: {
          title: `Add Single Subscriber`,
        },
      },
      {
        path: "edit-single-subscriber/:id",
        name: "editsinglesubscriber",
        component: () =>
          import(
            /* webpackChunkName: "EditSingleSubscriber" */ "../pages/Subscribers/Subscriber/EditSingleSubscriber.vue"
          ),
        meta: {
          title: `Edit Single Subscriber`,
        },
      },
      {
        path: "subscribers-list/:id",
        name: "PinListBatchWises",
        component: () =>
          import(
            /* webpackChunkName: "subscriber" */ "../pages/Subscribers/Subscriber/SubscriberListView.vue"
          ),
        meta: {
          title: `Subsciber List`,
        },
      },
      {
        path: "ip-lists",
        name: "iplists",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/VpnServer/IpList.vue"
          ),
        meta: {
          title: `Ip List`,
        },
      },
      {
        path: "add-ip",
        name: "addip",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/VpnServer/AddIp.vue"
          ),
        meta: {
          title: `Add Ip`,
        },
      },
      {
        path: "edit-ip/:id",
        name: "editip",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/VpnServer/EditIp.vue"
          ),
        meta: {
          title: `Edit Ip`,
        },
      },
      {
        path: "ip-bundle-lists",
        name: "ipbundlelists",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/IpBundle/IpBundleList.vue"
          ),
        meta: {
          title: `ip bundle list`,
        },
      },
      {
        path: "add-ip-bundle",
        name: "addipbundle",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/IpBundle/AddIpBundle.vue"
          ),
        meta: {
          title: `Add New ip bundle`,
        },
      },
      {
        path: "edit-ip-bundle/:id",
        name: "editipbundle",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/IpBundle/AddIpBundle.vue"
          ),
        meta: {
          title: `ip bundle list`,
        },
      },
      {
        path: "ip-bundle-details-lists/:id",
        name: "IpBundleDtlsLists",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/IpBundle/IpBundleDtlsList.vue"
          ),
        meta: {
          title: `ip bundle list`,
        },
      },
      {
        path: "add-server",
        name: "addserver",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/Server/AddServer.vue"
          ),
        meta: {
          title: `Add Server`,
        },
      },
      {
        path: "edit-server/:id",
        name: "editserver",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/Server/EditServer.vue"
          ),
        meta: {
          title: `Add Server`,
        },
      },
      {
        path: "server-lists",
        name: "serverlists",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/Server/ServerLists.vue"
          ),
        meta: {
          title: `Server List`,
        },
      },
      {
        path: "app-lists",
        name: "applists",
        component: () =>
          import(
            /* webpackChunkName: "AppList" */ "../pages/PushManagement/AppList.vue"
          ),
        meta: {
          title: `App List`,
        },
      },
      {
        path: "add-app",
        name: "addapp",
        component: () =>
          import(
            /* webpackChunkName: "AddApp" */ "../pages/PushManagement/AddApps.vue"
          ),
        meta: {
          title: `Add App`,
        },
      },
      {
        path: "edit-app/:id",
        name: "ediapp",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/PushManagement/AddApps.vue"
          ),
        meta: {
          title: `Edit App`,
        },
      },
      {
        path: "template-lists",
        name: "templatelists",
        component: () =>
          import(
            /* webpackChunkName: "TemplateLists" */ "../pages/PushManagement/TemplateList.vue"
          ),
        meta: {
          title: `Template List`,
        },
      },
      {
        path: "add-template",
        name: "addtemplate",
        component: () =>
          import(
            /* webpackChunkName: "AddTemplate" */ "../pages/PushManagement/AddTemplate.vue"
          ),
        meta: {
          title: `Add Template`,
        },
      },
      {
        path: "edit-template/:id",
        name: "editemplate",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/PushManagement/AddTemplate.vue"
          ),
        meta: {
          title: `Edit Template`,
        },
      },
      {
        path: "all-push-requests",
        name: "allpushrequests",
        component: () =>
          import(
            /* webpackChunkName: "AllPushRequests" */ "../pages/PushManagement/AllPushRequestList.vue"
          ),
        meta: {
          title: `All Push Requests`,
        },
      },
      {
        path: "send-new-push",
        name: "sentnewpush",
        component: () =>
          import(
            /* webpackChunkName: "SentNewPush" */ "../pages/PushManagement/SentNewPush.vue"
          ),
        meta: {
          title: `Sent New Push`,
        },
      },
      {
        path: "/install-vpn-server/:id",
        name: "installovpns",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/Server/InstallOvpn.vue"
          ),
        meta: {
          title: `Vpn Install`,
        },
      },
      {
        path: "add-rateplan",
        name: "addrateplans",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/RatePlan/AddRatePlan.vue"
          ),
        meta: {
          title: `Add Rateplan`,
        },
      },
      {
        path: "rateplan-lists",
        name: "rateplanlists",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/RatePlan/RatePlan.vue"
          ),
        meta: {
          title: `Rateplan List`,
        },
      },
      {
        path: "/edit-rateplans/:id",
        name: "editrateplan",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/RatePlan/EditRatePlan.vue"
          ),
        meta: {
          title: `Edit Rateplan`,
        },
      },
      {
        path: "/rateplans/:id",
        name: "saleingrateplans",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/RatePlan/SaleingRatePlan.vue"
          ),
        meta: {
          title: `Saleing Rateplan`,
        },
      },
      {
        path: "/add-sale-rate/:id",
        name: "addsalerate",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/RatePlan/AddSaleRate.vue"
          ),
        meta: {
          title: `Saleing Rateplan`,
        },
      },
      {
        path: "/edit-sale-rate/:id",
        name: "editsalerates",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/RatePlan/EditSaleRate.vue"
          ),
        meta: {
          title: `Saleing Rateplan`,
        },
      },
      {
        path: "subscriber-subscription-report",
        name: "subscribersubscriptionreport",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/Report/SubscriberSubscriptionReport.vue"
          ),
        meta: {
          title: `Subscription Report`,
        },
      },
      {
        path: "hourly-server-connection",
        name: "hourlyserverconnection",
        component: () =>
          import(
            /* webpackChunkName: "hourlyserverconnection" */ "../pages/Report/HourlyServerConnectionReport.vue"
          ),
        meta: {
          title: `Server Connection`,
        },
      },
      {
        path: "hourly-subscription-report",
        name: "hourlysubscriptionreport",
        component: () =>
          import(
            /* webpackChunkName: "hourlysubscriptionreport" */ "../pages/Report/HourlySubscriptionReport.vue"
          ),
        meta: {
          title: `Hourly Subscription Report`,
        },
      },
      {
        path: "online-transaction-report",
        name: "onlinetransactionreport",
        component: () =>
          import(
            /* webpackChunkName: "OnlineTransactionReport" */ "../pages/Report/OnlineTransactionReport.vue"
          ),
        meta: {
          title: `Online Transaction Report`,
        },
      },
      {
        path: "radius-auth-server",
        name: "radiusserver",
        component: () =>
          import(
            /* webpackChunkName: "RadiusServer" */ "../pages/Settings/RadiusServer.vue"
          ),
        meta: {
          title: `Radius Auth Server List`,
        },
      },
      {
        path: "add-add-radius-auth-server",
        name: "addnewradiusserver",
        component: () =>
          import(
            /* webpackChunkName: "AddRadiusServer" */ "../pages/Settings/AddRadiusServer.vue"
          ),
        meta: {
          title: `Add New Radius Server`,
        },
      },
      {
        path: "/edit-radius-auth-server/:id",
        name: "EditRadiusAuth",
        component: () =>
          import(
            /* webpackChunkName: "AddRadiusServer" */ "../pages/Settings/AddRadiusServer.vue"
          ),
        meta: {
          title: `Edit Radius Auth Server`,
        },
      },
      {
        path: "/install-auth-server/:id",
        name: "InstallAuth",
        component: () =>
          import(
            /* webpackChunkName: "InstallAuth" */ "../pages/Settings/InstallAuthServer.vue"
          ),
        meta: {
          title: `Install Auth Server`,
        },
      },
      {
        path: "feedback-lists",
        name: "FeedbackLists",
        component: () =>
          import(
            /* webpackChunkName: "AppList" */ "../pages/FeedbackManage/Feedback.vue"
          ),
        meta: {
          title: `Feedback List`,
        },
      },
      {
        path: "config-settings",
        name: "addconfigsettings",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/Settings/AddConfigSetting.vue"
          ),
        meta: {
          title: `Settings`,
        },
      },
      {
        path: "panel-settings",
        name: "addpanelsettings",
        component: () =>
          import(
            /* webpackChunkName: "AddSingleSubscriber" */ "../pages/Settings/AddPanelSetting.vue"
          ),
        meta: {
          title: `Settings`,
        },
      },
    ],
  },
];

const router = createRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("jwt_token") == null) {
      next({
        name: "login",
      });
    } else {
      next();
    }
  } else {
    if (to.name == "login") {
      if (localStorage.getItem("jwt_token") == null) {
        next();
      } else {
        next({
          name: "home",
        });
      }
    } else {
      next();
    }
  }
});

export default router;
